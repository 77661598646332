import { useAllSavedFilters } from '@capturi/api-filters'
import { useScore } from '@capturi/api-scoring'
import { useCurrentUser } from '@capturi/core'
import { usePageTitle } from '@capturi/react-utils'
import { t } from '@lingui/macro'
import NotFoundPage from 'pages/NotFoundPage'
import React from 'react'
import { useParams } from 'react-router-dom'

import { ScoreEditor, ScoreEditorProvider } from '../editor'

type EditPageProps = {
  uid?: string
}

const EditScorePage: React.FC<EditPageProps> = ({ uid }) => {
  const currentUser = useCurrentUser()

  const { data: score } = useScore(uid)
  const pageTitle = score ? t`Edit score: ${score?.name}` : 'Create score'
  usePageTitle(pageTitle)

  if (!currentUser.permissions.editScore) return <NotFoundPage />

  if (score == null) {
    return null
  }

  return <ScoreEditor uid={score.uid} />
}

export default function EditScorePageContainer(): React.ReactElement {
  const params = useParams()
  const { data: score } = useScore(params.uid)
  const { data: savedFilters } = useAllSavedFilters()

  const savedFilter = savedFilters?.find(
    (savedFilter) => savedFilter.uid === score?.savedFilterGroupUid,
  )
  return (
    <ScoreEditorProvider
      initialValues={(createFormModel) => createFormModel(score, savedFilter)}
    >
      <EditScorePage uid={params.uid} />
    </ScoreEditorProvider>
  )
}
